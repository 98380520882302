<template>
  <div class="search-filter">
    <a-form-model
        class="form"
        ref="form"
        :model="form"
        :label-col="{ flex: '0 0 113px' }"
        :wrapper-col="{ flex: '0 0 calc(100% - 113px)'}"
    >
      <a-row :gutter="16">
        <a-col :span="colSpan1">
          <a-form-model-item label="主体(品牌)" prop="principalId">
            <a-select
                v-model="form.principalId"
                :default-active-first-option="false"
                option-filter-prop="children"
                show-search
                style="width: 100%;"
                @change="handlePrincipalChange"
            >
              <a-select-option
                  v-for="item in principalList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="大区" prop="regionId">
            <a-select
                v-model="form.regionId"
                :disabled="form.principalId === ''"
                :default-active-first-option="false"
                option-filter-prop="children"
                show-search
                style="width: 100%;"
            >
              <a-select-option
                  v-for="item in regionList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="媒体号" prop="code">
            <a-input
                v-model.trim="form.code"
                allow-clear
                placeholder="请输入媒体号"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="播主昵称/经销商名称/公司名称" prop="searchText">
            <a-input
                v-model.trim="form.searchText"
                allow-clear
                placeholder="请输入播主昵称/经销商名称/公司名称"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="平台" prop="platformType">
            <a-select
                v-model="form.platformType"
                style="width: 100%;"
            >
              <a-select-option
                  v-for="item in platformList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="P2授权" prop="p2OauthStatus">
            <a-select
                v-model="form.p2OauthStatus"
                style="width: 100%;"
            >
              <a-select-option
                  v-for="item in p2OauthStatusList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="P1授权" prop="p1OauthStatus">
            <a-select
                v-model="form.p1OauthStatus"
                style="width: 100%;"
            >
              <a-select-option
                  v-for="item in p1OauthStatusList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="P9授权" prop="p9OauthStatus">
            <a-select
                v-model="form.p9OauthStatus"
                style="width: 100%;"
            >
              <a-select-option key="" value="">全部</a-select-option>
              <a-select-option
                  v-for="item in p9OauthStatusList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan2">
          <a-form-model-item label="按分类选择分组" prop="groupId">
            <div class="group-wrap">
              <a-button-group>
                <a-button
                    v-for="item in groupCateList"
                    :key="item.value"
                    :type="item.value === form.groupCate ? 'primary' : 'default'"
                    style="padding: 0 8px;"
                    @click="handleGroupCateChange(item.value)"
                >
                  {{ item.label }}
                </a-button>
              </a-button-group>
              <a-select
                  v-model="form.groupId"
                  :default-active-first-option="false"
                  :filter-option="false"
                  show-search
                  placeholder="先选分类，再选分组"
                  style="width: 100%;"
                  @search="handleGroupSearch"
              >
                <a-select-option
                    v-for="item in groupList"
                    :key="item.value"
                    :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan3">
          <a-form-model-item :wrapper-col="{ flex: '1 0 auto' }">
            <div class="btn-wrap">
              <a-button class="mr-8" @click="handleReset">
                重置
              </a-button>
              <a-button type="primary" :loading="loading" @click="handleSearch">
                查询
              </a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import resizeMixin from "@/mixins/dataTableMixin";
import { platformList, p2OauthStatusList, p1OauthStatusList, p9OauthStatusList } from "../../constant";

const groupCateList = [
  {label: "经销商公共分组", value: 1},
  {label: "播主公共分组", value: 2},
  {label: "我的经销商分组", value: 3}
];

export default {
  name: "SearchFilter",
  mixins: [resizeMixin],
  props: {
    form: {
      type: Object,
      default() {
        return {};
      }
    },
    fetchPrincipal: {
      type: Function,
      default() {
        return () => {
        };
      }
    },
    principalList: {
      type: Array,
      default() {
        return [];
      }
    },
    fetchRegion: {
      type: Function,
      default() {
        return () => {
        };
      }
    },
    regionList: {
      type: Array,
      default() {
        return [];
      }
    },
    fetchGroup: {
      type: Function,
      default() {
        return () => {
        };
      }
    },
    groupList: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.$_resizeHandler = () => {
      this.setColSpan();
    };

    return {
      platformList: [
        {label: "全部", value: ""},
        ...platformList
      ],
      p2OauthStatusList: [
        {label: "全部", value: ""},
        {label: "未授权", value: 0},
        {label: "已授权", value: 1},
        {label: "授权过期", value: 2},
        {label: "处理中", value: 4}
      ],
      p1OauthStatusList: [
        {label: "全部", value: ""},
        {label: "未授权", value: 0},
        {label: "已授权", value: 1},
        {label: "授权过期", value: 2},
        // {label: "处理中", value: 4}
      ],
      p9OauthStatusList,
      groupCateList,
      colSpan1: 12,
      colSpan2: 24,
      colSpan3: 24
    };
  },
  created() {
    this.fetchPrincipal();
    this.setColSpan();
  },
  methods: {
    handlePrincipalChange(val) {
      this.form.regionId = "";
      this.fetchRegion(val);
    },
    handleGroupCateChange(val) {
      console.log(val);
      if(val === this.form.groupCate) {
        this.form.groupCate = "";
        this.form.groupId = undefined;
      } else {
        this.form.groupCate = val;
        this.form.groupId = "";
      }

      this.fetchGroup(this.form.groupCate, "");
    },
    handleGroupSearch(searchText) {
      this.fetchGroup(this.form.groupCate, searchText);
    },
    handleReset() {
      this.$refs.form.resetFields();
      this.fetchRegion("");
      this.form.groupCate = "";
      this.form.groupId = undefined;
      this.fetchGroup(this.form.groupCate, "");
      this.$emit("search", {...this.form});
    },
    handleSearch() {
      this.$emit("search", {...this.form});
    },
    setColSpan() {
      const width = document.documentElement.offsetWidth;

      if(width < 1200) {
        this.colSpan1 = 12;
        this.colSpan2 = 24;
        this.colSpan3 = 24;
      } else if(width >= 1200 && width < 1600) {
        this.colSpan1 = 8;
        this.colSpan2 = 16;
        this.colSpan3 = 24;
      } else if(width >= 1600) {
        this.colSpan1 = 6;
        this.colSpan2 = 12;
        this.colSpan3 = 12;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.search-filter {
  padding: 24px 24px 16px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .ant-form-item {
    display: flex;
    margin-bottom: 8px;

    /deep/ .ant-form-item-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 20px;
      white-space: pre-wrap;
    }
  }

  .group-wrap {
    display: flex;
    align-items: center;
    height: 40px;

    .ant-btn-group {
      margin-right: 8px;
    }

    .ant-select {
      flex: 1;
    }
  }

  .time-wrap {
    display: flex;
    align-items: center;
    height: 40px;

    .ant-btn-group {
      margin-right: 8px;
    }

    .ant-calendar-picker {
      flex: 1;
    }
  }

  .btn-wrap {
    text-align: right;
  }

  .mr-8 {
    margin-right: 8px;
  }
}
</style>

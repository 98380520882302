export const platformList = [
  {label: "抖音", value: 1},
  {label: "快手", value: 2}
];

export const platformFilter = value => {
  const index = platformList.findIndex(item => item.value === value);
  return index > -1 ? platformList[index].label : "-";
};

export const p2OauthStatusList = [
  {label: "未授权", value: 0},
  {label: "已授权", value: 1},
  {label: "已授权", value: 3},
  {label: "授权过期", value: 2},
  {label: "处理中", value: 4}
];

export const p2OauthStatusFilter = value => {
  const index = p2OauthStatusList.findIndex(item => item.value === value);
  return index > -1 ? p2OauthStatusList[index].label : "-";
};

export const p1OauthStatusList = [
  {label: "未授权", value: 0},
  {label: "已授权", value: 1},
  {label: "已授权", value: 3},
  {label: "授权过期", value: 2},
];

export const p1OauthStatusFilter = value => {
  const index = p1OauthStatusList.findIndex(item => item.value === value);
  return index > -1 ? p1OauthStatusList[index].label : "-";
};

export const p9OauthStatusList = [
  {label: "未授权", value: 0},
  {label: "已授权", value: 1},
  // {label: '授权过期', value: 2}
];

export const p9OauthStatusFilter = value => {
  const index = p9OauthStatusList.findIndex(item => item.value === value);
  return index > -1 ? p9OauthStatusList[index].label : "-";
};

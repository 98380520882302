<template>
  <div class="data-table" ref="dataTable">
    <a-row class="action-bar" type="flex" ref="actionBar">
      <a-col class="action-bar-left" flex="1 0 auto">
        <a-button
          type="primary"
          :disabled="selectedRecordIds.length === 0"
          @click="handleJoinGroup"
        >
          加入分组
          <span v-if="selectedRecordIds.length > 0">
            ({{ selectedRecordIds.length }})
          </span>
        </a-button>
      </a-col>
      <a-col class="action-bar-right" flex="1 0 auto">
        <a-button
          type="primary"
          :loading="exportLoading"
          @click="handleExport"
        >
          导出
        </a-button>
      </a-col>
    </a-row>

    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => record.authorId"
      :row-selection="{
        columnWidth: 60,
        selectedRowKeys: selectedRecordIds,
        onSelect: onSelect,
        onSelectAll: onSelectAll
      }"
      :pagination="false"
      :scroll="scroll"
    >
      <div class="author-info" slot="authorInfo" slot-scope="text, record">
        <div class="author-info__hd">
          <a-avatar :src="record.avatar" :size="64" icon="user" />  
        </div>
        <div class="author-info__bd">
          <div>昵称：{{ record.nickname || '-' }}</div>
          <div>媒体号：{{ record.code }}</div>
        </div>
      </div>
      <div class="platform-info" slot="platformInfo" slot-scope="text, record">
        {{ record.platformType | platformFilter }}
      </div>
      <div class="auth-info" slot="authInfo" slot-scope="text, record">
        <div>互动数据(P2)：{{ record.p2OauthStatus | p2OauthStatusFilter }}</div>
        <div>直播数据(P1)：{{ record.p1OauthStatus | p1OauthStatusFilter }}</div>
        <div v-if="record.platformType == 1">线索数据(P9)：{{ text.p9OauthStatus | p9OauthStatusFilter }}</div>
      </div>
      <div class="dealer-info" slot="dealerInfo" slot-scope="text, record">
        <div>名称：{{ record.dealerName || '-' }}</div>
        <div>编码：{{ record.dealerStoreCode || '-' }}</div>
        <div>大区：{{ record.dealerAreaNames || '-' }}</div>
        <div>城市：{{ record.dealerCityName || '-' }}</div>
        <div>主体：{{ record.principalNames || '-' }}</div>
      </div>
      <div class="group-info" slot="groupInfo" slot-scope="text, record">
        <router-link
          :to="{ name: 'authorGroupList', query: { nickname: record.nickname } }"
          target="_blank"
        >
          {{ record.commonGroupCount | humanReadable }}
        </router-link>
      </div>
      <div class="operate" slot="operate" slot-scope="text, record">
        <router-link
          :to="{
            name: 'authorAnalysis',
            params: { authorId: record.authorId },
            query: { platform: record.platformType }
          }"
          target="_blank"
        >
          播主分析  
        </router-link>

        <a-divider type="vertical" />
        <a @click="handleOperate('joinGroup', record)">加入分组</a>

        <a-divider type="vertical" />
        <router-link
          :to="{
            name: record.platformType === 1 ? 'authorAuth' : 'authorAuthKS',
            params: { authorId: record.authorId }
          }"
          target="_blank"
        >
          播主授权  
        </router-link>
      </div>
    </a-table>

    <BasePagination
      ref="basePagination"
      :currentPage="pagination.page"
      :pageSize="pagination.size"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import resizeMixin from '@/mixins/dataTableMixin'
import { platformFilter, p2OauthStatusFilter, p1OauthStatusFilter, p9OauthStatusFilter } from '../../constant'

const columns = [
  {
    title: '播主',
    key: 'authorInfo',
    width: 220,
    scopedSlots: { customRender: 'authorInfo' }
  },
  {
    title: '平台',
    key: 'platformInfo',
    width: 80,
    scopedSlots: { customRender: 'platformInfo' }
  },
  {
    title: '平台授权',
    key: 'authInfo',
    width: 200,
    scopedSlots: { customRender: 'authInfo' }
  },
  {
    title: '经销商信息',
    key: 'dealerInfo',
    width: 200,
    scopedSlots: { customRender: 'dealerInfo' }
  },
  {
    title: '公共分组',
    key: 'groupInfo',
    width: 70,
    scopedSlots: { customRender: 'groupInfo' }
  },
  {
    title: '操作',
    key: 'operate',
    width: 242,
    fixed: 'right',
    scopedSlots: { customRender: 'operate' }
  }
]

export default {
  name: 'DataTable',
  mixins: [resizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0
        };
      }
    },
    exportLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns,
      selectedRecordIds: [],
      selectedRecords: [],
      scroll: {}
    }
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.setTableScroll()
      })
    }
  },
  methods: {
    handlePaginationChange(page, size) {
      this.$emit('pagination-change', page, size)
    },
    handleExport() {
      this.$emit('export')
    },
    onSelect(record, selected, selectedRows, nativeEvent) {
      // console.log(record, selected, selectedRows, nativeEvent)

      const index = this.selectedRecordIds.findIndex(id => id === record.authorId)

      if (selected && index === -1) { // 选择时
        this.selectedRecordIds.push(record.authorId)
        this.selectedRecords.push(record)
      }

      if (!selected && index > -1) { // 取消选择时
        this.selectedRecordIds.splice(index, 1)
        this.selectedRecords.splice(index, 1)
      }
    },
    onSelectAll(selected, selectedRows, changeRows) {
      // console.log(selected, selectedRows, changeRows)

      if (selected) {
        selectedRows.forEach(row => {
          const index = this.selectedRecordIds.findIndex(id => id === row.authorId)

          index === -1 && this.selectedRecordIds.push(row.authorId)
          index === -1 && this.selectedRecords.push(row)
        })
      } else {
        changeRows.forEach(row => {
          const index = this.selectedRecordIds.findIndex(id => id === row.authorId)

          index > -1 && this.selectedRecordIds.splice(index, 1)
          index > -1 && this.selectedRecords.splice(index, 1)
        })
      }
    },
    handleJoinGroup() {
      this.$emit(
        'join-group',
        [...this.selectedRecordIds],
        [...this.selectedRecords.map(record => { return { ...record } })]
      )
    },
    resetSelectedRecords() {
      this.selectedRecordIds = []
      this.selectedRecords = []
    },
    handleOperate(type, record) {
      this.$emit('operate', type, record)
    },
    setTableScroll() {
      const winHeight = document.documentElement.offsetHeight || document.body.offsetHeight
      const width = this.$refs.dataTable.offsetWidth
      const height = this.$refs.dataTable.offsetHeight
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0)
      const scroll = {}

      if (width - 48 < columnsWidth) {
        scroll.x = columnsWidth
      }

      if (height + 142 > winHeight) {
        const actionBarHeight = this.$refs.actionBar ? this.$refs.actionBar.$el.offsetHeight : 0
        const tableHeadHeight = document.querySelector('.ant-table-thead').offsetHeight
        const otherHeight  = actionBarHeight + tableHeadHeight + 190
        
        scroll.y = `calc(100vh - ${otherHeight}px)`
      }

      this.scroll = scroll
    },
  },
  filters: {
    platformFilter,
    p2OauthStatusFilter,
    p1OauthStatusFilter,
    p9OauthStatusFilter
  },
  mounted() {
    this.isScrollY = false
    this.setTableScroll()
    this.$_resizeHandler = () => {
      this.setTableScroll()
    }
  }
}
</script>

<style lang="scss" scoped>
.data-table {
  padding: 24px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .action-bar {
    padding: 0 0 16px;
  }
  .action-bar-right {
    text-align: right;
  }

  .author-info {
    display: flex;
    align-items: center;

    &__hd {
      margin-right: 8px;
    }
  }
}
</style>

<template>
  <section class="page">
    <SearchFilter
        class="mb-16"
        :form="searchForm"
        :fetch-principal="fetchPrincipal"
        :principal-list="principalList"
        :fetch-region="fetchRegion"
        :region-list="regionList"
        :fetch-group="fetchGroup"
        :group-list="groupList"
        :loading="loading"
        @search="handleSearch"
    />

    <DataTable
        ref="dataTable"
        :loading="loading"
        :data="authorList"
        :pagination="pagination"
        :export-loading="exportLoading"
        @pagination-change="handlePaginationChange"
        @export="handleExport"
        @join-group="handleJoinGroup"
        @operate="handleOperate"
    />

    <JoinAuthorGroupModal
        ref="joinAuthorGroupModal"
        v-model="joinAuthorGroupVisible"
        :form="joinAuthorGroupForm"
        :group-list="joinAuthorGroupList"
        :confirm-loading="joinAuthorGroupLoading"
        @add-group="addAuthorGroupVisible = true"
        @confirm="handleJoinAuthorGroupConfirm"
    />

    <AddAuthorGroupModal
        ref="addAuthorGroupModal"
        v-model="addAuthorGroupVisible"
        :form="addAuthorGroupForm"
        :confirm-loading="addAuthorGroupLoading"
        @confirm="handleAddAuthorGroupConfirm"
    />
  </section>
</template>

<script>
import SearchFilter from "./components/SearchFilter";
import DataTable from "./components/DataTable";
import JoinAuthorGroupModal from "@/components/JoinAuthorGroupModal";
import AddAuthorGroupModal from "@/components/AddAuthorGroupModal";
import baseApi from "@/api/base";
import dealerApi from "@/api/dealer";
import authorApi from "@/api/author";

export default {
  name: "AuthorList",
  components: {
    SearchFilter,
    DataTable,
    JoinAuthorGroupModal,
    AddAuthorGroupModal
  },
  data() {
    return {
      searchForm: {
        principalId: "",
        regionId: "",
        code: "",
        searchText: "",
        platformType: "",
        p2OauthStatus: "",
        p1OauthStatus: "",
        p9OauthStatus: '',
        groupCate: "",
        groupId: undefined
      },
      principalList: [
        {label: "全部", value: ""}
      ],
      regionList: [
        {label: "全部", value: ""}
      ],
      groupList: [],
      loading: false,
      authorList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 10
      },
      exportLoading: false,
      joinAuthorGroupVisible: false,
      joinAuthorGroupType: "",
      joinAuthorGroupForm: {
        authorList: [],
        groupIdList: []
      },
      joinAuthorGroupList: [],
      joinAuthorGroupLoading: false,
      addAuthorGroupVisible: false,
      addAuthorGroupForm: {
        groupType: 1,
        groupName: ""
      },
      addAuthorGroupLoading: false
    };
  },
  mounted() {
    const {dealerName, authorGroupId, authorGroupName, groupCate, isGroupCate} = this.$route.query;

    dealerName && (this.searchForm.searchText = dealerName);
    if(isGroupCate) {
      if(authorGroupId && groupCate == 1) {
        this.searchForm.groupCate = 1;
        this.searchForm.groupId = authorGroupId;
        this.fetchGroup(1, authorGroupName);
      }
    } else if(authorGroupId && authorGroupName) {
      this.searchForm.groupCate = 2;
      this.searchForm.groupId = authorGroupId;
      this.fetchGroup(2, authorGroupName);
    }


    this.fetchAuthorList();
  },
  methods: {
    //
    async fetchPrincipal() {
      try {
        const {code, data, message} = await baseApi.fetchPrincipalList();

        if(code === 200) {
          this.principalList = [
            {label: "全部", value: ""},
            ...data.map(item => {
              return {label: item.principal_name, value: item.id};
            })
          ];
        } else {
          this.$message.error(message);
          this.principalList = [];
        }
      } catch(e) {
        console.error(e);
        this.principalList = [];
      }
    },
    //
    async fetchRegion(principalId) {
      if(!principalId) {
        this.regionList = [{label: "全部", value: ""}];
        return false;
      }

      try {
        const params = {principalId};
        const {code, data, message} = await baseApi.fetchRegionList(params);

        if(code === 200) {
          console.log(data);
          this.regionList = [
            {label: "全部", value: ""},
            ...data.map(item => {
              return {label: item.region_names, value: item.region_id};
            })
          ];
        } else {
          this.$message.error(message);
          this.regionList = [];
        }
      } catch(e) {
        console.error(e);
        this.regionList = [];
      }
    },
    fetchGroup(groupType, searchText) {
      if(!groupType) {
        this.groupList = [];
        return false;
      }

      if(groupType && !searchText) {
        this.groupList = [{label: "全部", value: ""}];
        return false;
      }

      groupType === 1 && this.fetchDealerPublicGroupList(searchText);
      groupType === 2 && this.fetchAuthorPublicGroupList(searchText);
      groupType === 3 && this.fetchMyDealerGroupList(searchText);
    },
    async fetchDealerPublicGroupList(searchText) {
      try {
        const params = {
          name: searchText,
          pageNo: 1,
          limit: 50
        };
        const {code, data, msg, errmsg} = await dealerApi.fetchDealerPublicGroupList(params);

        if(code === 0) {
          this.groupList = data.data.map(item => {
            return {label: item.groupName, value: item.groupId};
          });
        } else {
          this.$message.error(msg || errmsg);
          this.groupList = [];
        }
      } catch(e) {
        console.error(e);
        this.groupList = [];
      }
    },
    async fetchAuthorPublicGroupList(searchText) {
      try {
        const params = {
          name: searchText,
          page: 1,
          size: 50
        };
        const {code, data, message} = await authorApi.fetchAuthorPublicGroupList(params);

        if(code === 200) {
          this.groupList = data.list.map(item => {
            return {label: item.name, value: item.id};
          });
        } else {
          this.$message.error(message);
          this.groupList = [];
        }
      } catch(e) {
        console.error(e);
        this.groupList = [];
      }
    },
    async fetchMyDealerGroupList(searchText) {
      try {
        const params = {
          groupName: searchText
        };
        const {code, data, message} = await dealerApi.fetchMyDealerGroupList(params);

        if(code === 200) {
          this.rawGroupList = data;
          this.groupList = data.map(item => {
            return {label: item.groupName, value: item.groupId};
          });
        } else {
          this.$message.error(message);
          this.groupList = [];
        }
      } catch(e) {
        console.error(e);
        this.groupList = [];
      }
    },
    handleSearch() {
      this.$refs.dataTable.resetSelectedRecords();
      this.pagination.page = 1;
      this.fetchAuthorList();
    },
    handlePaginationChange(page, size) {
      this.pagination.page = page;
      this.pagination.size = size;
      this.fetchAuthorList();
    },
    // 获取播主列表
    async fetchAuthorList() {
      this.loading = true;
      try {
        const {groupCate, groupId, ...restSearchForm} = this.searchForm;
        const {page, size} = this.pagination;
        const params = {
          ...restSearchForm,
          page,
          size
        };

        if(groupCate) {
          params.groupType = groupCate !== 2 ? 1 : 2;
          params.isCommon = (
              groupCate !== 3 ? 1 : (groupId ? this.getIsCommon(groupId) : "")
          );
          params.groupId = groupId || "";
        }

        const {code, data, message} = await authorApi.fetchAuthorList(params);

        this.loading = false;

        if(code === 200) {
          this.authorList = data?.list || [];
          this.pagination.total = data?.total || 0;
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        this.loading = false;
        console.error(e);
      }
    },
    // 处理导出
    async handleExport() {
      this.exportLoading = true;
      try {
        const {groupCate, groupId, ...restSearchForm} = this.searchForm;
        const params = {
          ...restSearchForm
        };

        if(groupId) {
          params.groupType = groupCate !== 2 ? 1 : 2;
          params.isCommon = groupCate !== 3 ? 1 : this.getIsCommon(groupId);
          params.groupId = groupId;
        }

        await authorApi.exportAuthorList(params);
        this.exportLoading = false;
      } catch(e) {
        console.error(e);
        this.exportLoading = false;
      }
    },
    getIsCommon(groupId) {
      const index = this.rawGroupList.findIndex(item => item.groupId === groupId);

      return index > -1 && this.rawGroupList[index].isCommon;
    },
    handleOperate(type, record) {
      type === "joinGroup" && this.openJoinAuthorGroupModal(
          "single",
          [{
            authorId: record.authorId,
            nickname: record.nickname,
            verifyName: record.dealerCompanyName,
            code: record.code,
            platform: record.platformType
          }]
      );
    },
    handleJoinGroup(recordIds, records) {
      this.openJoinAuthorGroupModal("multiple", [
        ...records.map(record => {
          return {
            authorId: record.authorId,
            nickname: record.nickname,
            verifyName: record.dealerCompanyName,
            code: record.code,
            platform: record.platformType
          };
        })
      ]);
    },
    openJoinAuthorGroupModal(type, authorList) {
      this.joinAuthorGroupVisible = true;
      this.joinAuthorGroupType = type;
      this.joinAuthorGroupForm.authorList = authorList;
      this.fetchMyAuthorGroupList();
    },
    //
    async fetchMyAuthorGroupList() {
      try {
        const {code, data, message} = await authorApi.fetchMyAuthorGroupList();

        if(code === 200) {
          this.joinAuthorGroupList = (data || []).filter(item => item.userType === 1);
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.error(e);
      }
    },
    handleJoinAuthorGroupConfirm() {
      this.joinAuthorGroup();
    },
    // 加入播主分组
    async joinAuthorGroup() {
      this.joinAuthorGroupLoading = true;
      try {
        const {authorList, groupIdList} = this.joinAuthorGroupForm;
        const params = {
          authorList,
          groupList: groupIdList.map(id => {
            const index = this.joinAuthorGroupList.findIndex(item => item.groupId === id);
            const group = this.joinAuthorGroupList[index];
            const {groupId, groupName, isCommon} = group;

            return {
              groupId,
              groupName,
              groupType: isCommon === 1 ? 1 : 2
            };
          })
        };
        const {code, message} = await authorApi.joinAuthorGroup(params);

        this.joinAuthorGroupLoading = false;

        if(code === 200) {
          this.joinAuthorGroupType === "single" && this.$message.success("已将该播主加入分组！");

          if(this.joinAuthorGroupType === "multiple") {
            this.$message.success(`已将${ authorList.length }个播主加入分组！`);
            this.$refs.dataTable.resetSelectedRecords();
          }

          this.joinAuthorGroupVisible = false;
          this.$refs.joinAuthorGroupModal.resetForm();
          this.fetchAuthorList();
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.error(e);
        this.joinAuthorGroupLoading = false;
      }
    },
    handleAddAuthorGroupConfirm() {
      this.addAuthorGroup();
    },
    // 新建播主分组
    async addAuthorGroup() {
      this.addAuthorGroupLoading = true;
      try {
        const {groupType, groupName} = this.addAuthorGroupForm;
        const params = {
          groupType,
          groupName
        };

        groupType === 2 && (params.source = 2);

        const {code, message} = await authorApi.addAuthorGroup(params);

        this.addAuthorGroupLoading = false;

        if(code === 200) {
          this.$message.success("新建播主分组成功");
          this.addAuthorGroupVisible = false;
          this.$refs.addAuthorGroupModal.resetForm();
          this.fetchMyAuthorGroupList();
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.error(e);
        this.addAuthorGroupLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mb-16 {
  margin-bottom: 16px;
}

.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}
</style>

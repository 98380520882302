var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"dataTable",staticClass:"data-table"},[_c('a-row',{ref:"actionBar",staticClass:"action-bar",attrs:{"type":"flex"}},[_c('a-col',{staticClass:"action-bar-left",attrs:{"flex":"1 0 auto"}},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.selectedRecordIds.length === 0},on:{"click":_vm.handleJoinGroup}},[_vm._v(" 加入分组 "),(_vm.selectedRecordIds.length > 0)?_c('span',[_vm._v(" ("+_vm._s(_vm.selectedRecordIds.length)+") ")]):_vm._e()])],1),_c('a-col',{staticClass:"action-bar-right",attrs:{"flex":"1 0 auto"}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.exportLoading},on:{"click":_vm.handleExport}},[_vm._v(" 导出 ")])],1)],1),_c('a-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.data,"row-key":function (record, index) { return record.authorId; },"row-selection":{
      columnWidth: 60,
      selectedRowKeys: _vm.selectedRecordIds,
      onSelect: _vm.onSelect,
      onSelectAll: _vm.onSelectAll
    },"pagination":false,"scroll":_vm.scroll},scopedSlots:_vm._u([{key:"authorInfo",fn:function(text, record){return _c('div',{staticClass:"author-info"},[_c('div',{staticClass:"author-info__hd"},[_c('a-avatar',{attrs:{"src":record.avatar,"size":64,"icon":"user"}})],1),_c('div',{staticClass:"author-info__bd"},[_c('div',[_vm._v("昵称："+_vm._s(record.nickname || '-'))]),_c('div',[_vm._v("媒体号："+_vm._s(record.code))])])])}},{key:"platformInfo",fn:function(text, record){return _c('div',{staticClass:"platform-info"},[_vm._v(" "+_vm._s(_vm._f("platformFilter")(record.platformType))+" ")])}},{key:"authInfo",fn:function(text, record){return _c('div',{staticClass:"auth-info"},[_c('div',[_vm._v("互动数据(P2)："+_vm._s(_vm._f("p2OauthStatusFilter")(record.p2OauthStatus)))]),_c('div',[_vm._v("直播数据(P1)："+_vm._s(_vm._f("p1OauthStatusFilter")(record.p1OauthStatus)))]),(record.platformType == 1)?_c('div',[_vm._v("线索数据(P9)："+_vm._s(_vm._f("p9OauthStatusFilter")(text.p9OauthStatus)))]):_vm._e()])}},{key:"dealerInfo",fn:function(text, record){return _c('div',{staticClass:"dealer-info"},[_c('div',[_vm._v("名称："+_vm._s(record.dealerName || '-'))]),_c('div',[_vm._v("编码："+_vm._s(record.dealerStoreCode || '-'))]),_c('div',[_vm._v("大区："+_vm._s(record.dealerAreaNames || '-'))]),_c('div',[_vm._v("城市："+_vm._s(record.dealerCityName || '-'))]),_c('div',[_vm._v("主体："+_vm._s(record.principalNames || '-'))])])}},{key:"groupInfo",fn:function(text, record){return _c('div',{staticClass:"group-info"},[_c('router-link',{attrs:{"to":{ name: 'authorGroupList', query: { nickname: record.nickname } },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("humanReadable")(record.commonGroupCount))+" ")])],1)}},{key:"operate",fn:function(text, record){return _c('div',{staticClass:"operate"},[_c('router-link',{attrs:{"to":{
          name: 'authorAnalysis',
          params: { authorId: record.authorId },
          query: { platform: record.platformType }
        },"target":"_blank"}},[_vm._v(" 播主分析 ")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.handleOperate('joinGroup', record)}}},[_vm._v("加入分组")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('router-link',{attrs:{"to":{
          name: record.platformType === 1 ? 'authorAuth' : 'authorAuthKS',
          params: { authorId: record.authorId }
        },"target":"_blank"}},[_vm._v(" 播主授权 ")])],1)}}])}),_c('BasePagination',{ref:"basePagination",attrs:{"currentPage":_vm.pagination.page,"pageSize":_vm.pagination.size,"total":_vm.pagination.total},on:{"onChange":_vm.handlePaginationChange,"onShowSizeChange":_vm.handlePaginationChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }